:root {
  --box-bg-color: #fdede0; 
  --blue-font:#003459;
  --heading-font-color:#003459;
  --heading-font-size:40px;
  --heading-font-weight:600;
  --title-font-color:#003459;
  --title-font-size:24px;
  --title-font-weight:600;
  --light-font-color:#545454;
  --more-light-gray:#CFCFCF;
  --gray-font:#545454;
  --icon-bg-color:#fdede0;
   --icon-bg-border:#f9e0cd; 
  --icon-bg-color-active:#003459;
  --icon-color:#003459;
  --box-border:#F9DECA;
  --btn-bg-color:#0e3459;
  --btn-hover-color:#050a30;
  --btn-text-color:#fff;
  --line-color:#003459;
  --main-img-bgcolor:#FDEDE0;
  --main-img-border:#F9DECA;
  --stepper-iconbg:#eef8ff;
  --link-text:#003459;
  --heading-title-size:18px;
  --heading-title-color:#969696;
  --active-list-bgcolor:#eef8ff;
  --dark-grey:#969696;
  --heading-fontfamily: 'Rubik', sans-serif;
  --content-fontfamily:'DM Sans', sans-serif;
  --Greyish-Black: #545454
} 
body{ font-family: var(--content-fontfamily)}
.max-w-1000{ max-width: 1400px;}
.w-30{ width: 30%;}
.w-70{ width: 70%;}
h1{
  font-size: var(--heading-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-font-weight); 
  font-family: var(--heading-fontfamily);
}
h4{
  font-size: var(--title-font-size);
  color: var(--title-font-color);
  font-weight: var(--title-font-weight);
  font-family: var(--heading-fontfamily);
}
h5{ 
  font-family:  var(--heading-fontfamily);
  font-size: 21px; font-weight: 600; color: var(--Greyish-Black);
}
h6{
  font-family:  var(--heading-fontfamily);
  font-size: 18px; font-weight: 600; color: var(--blue-font);
}
.blue-font{ color: var(--blue-font);}
.drak-gray{ color: var(--dark-grey);}
.more-light-gray{ color: var(--more-light-gray);}
.headingtitle{ font-family: var(--content-fontfamily); font-weight: 400; font-size: var(--heading-title-size); color: var(--heading-title-color); margin-bottom: 22px;}
.lightfont{  color: var(--light-font-color); font-size: 14px !important; font-family: var(--content-fontfamily); font-weight: normal; }
.lightfontbold{  color: var(--light-font-color); font-family: var(--content-fontfamily);}
.categories li{ background: var(--box-bg-color);border: 1px solid #fff; padding: 20px; border-radius: 10px; }
.categories li .catimg { background: #fff;}
.categories li:hover{ border: 1px solid var(--icon-bg-color-active);}
.iconbg{ background: var(--icon-bg-color); color: var(--icon-color); border: 2px solid var(--icon-bg-border);}
.gray-font{ color: var(--gray-font);}
.border-box{ border: 3px solid var(--box-border); border-radius: 8px;}
.icontext-sm { font-size: 14px; font-weight: 500; text-align: center;}
.mt--16{ margin-top: -16px;}
.vline{ border: 1px solid var(--line-color); }
.catname{ font-family: var(--heading-fontfamily);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;}
.btn-normal{ background: var(--btn-bg-color) ; color: var(--btn-text-color); border-radius: 4px; padding: 4px 20px; text-align: center; }
.mainImage{ width: 100%; background: var(--main-img-bgcolor); border:20px solid var(--main-img-bgcolor);   border-radius: 15px; }  
.mainImage > div { border: 3px solid var(--main-img-border);border-radius: 10px; display: flex; justify-content: center;}
.pl-15 { padding-left: 15px; }
.accordianborder ul li input{ cursor: pointer;}
.accordianborder ul li{ color: var(--Greyish-Black); border:2px solid #f1f1f1; line-height: 42px; padding: 7px; border-radius: 10px; cursor: pointer;}
.accordianborder ul li.active,.accordianborder ul li:hover{ background:var(--active-list-bgcolor) ; border: 1px solid var(--btn-bg-color); margin: 1px 0px;  }
.accordianborder.activeheader {border: 1px solid var(--line-color); }
.accordianborder button{ align-items: flex-start; font-family: var(--heading-fontfamily); font-size: 22px;padding: 25px 25px 30px 25px; }
.accordianborder{ border: 2px solid #d8d8d8; overflow: hidden; border-radius: 15px; padding: 0px; font-family: var(--heading-fontfamily); font-weight: 600;}
.activeheader .accorHeader { background: var(--main-img-bgcolor) ; color: var(--heading-font-color); }
.accordianborder.accorHeader{ border: 1px solid var(--line-color); }
 .submitbtn{border-radius: 7px; background: var(--btn-bg-color);border: 1px solid #000; color: #fff;height: 63px;
  min-width: 216px; white-space: nowrap;
  padding: 20px 30px;font-size: 18px;
  font-style: normal;
  font-weight: 500;}
  .submitbtn:hover{background: var(--btn-hover-color);  }
 
  
.submitdesign{ background: #FFFCFA; border:3px solid var(--box-border);border-radius: 10px;padding: 35px; align-items: center; }
  #slide-down-div {
    max-height: 0;
  overflow: hidden;
  transition: max-height 5.5s ease;
  } 
  .radius-15{ border-radius: 15px;}
  .popular-tag{ border-radius: 20px; margin-left: 5px; position: relative; top:-2px; font-size: 11px; padding: 5px 10px; color: #fff; background-color: var(--btn-bg-color); font-weight: normal; font-family: var(--content-fontfamily);}
  .activeimgborder{ border: 3px solid var(--box-border); }
.selectedvallist { column-gap: 10px;   flex-wrap:wrap ;}
.selectedvallist li { margin-top: 10px; background: #fff; border: 2px solid var(--box-border); padding: 6px;  color: var(--blue-font); font-weight: 500; border-radius: 6px; white-space: nowrap; }
.customsteper .iconbg{ background: #cfcfcf;width: 36px;  height: 36px; border: 1px solid #d8d8d8; color: #fff;}
.customsteper .vline{ border: 1px solid #d8d8d8;}
.activeicon  .iconbg { background:  var(  --stepper-iconbg); color: var(--icon-color); /* border: 1px solid var(--line-color); */}
.customsteper .vline.activeline{ border: 1px solid var(--line-color); }
.customsteper > div { position: relative;}
.customsteper .icontext-sm{ text-align: center;    height: 23px; position: absolute; bottom: -20px; color: var(--more-light-gray);    line-height: inherit;}
.customsteper .activesteptext{ color: var(--link-text);}
.completedicon .iconbg  { width: 36px; height: 36px; background: var(--icon-bg-color-active); color: #fff;}
.backbtn{ height: 50px; cursor: pointer;
  min-width: 150px;background: var(--btn-bg-color);color: var(--btn-text-color);
  padding: 20px 30px;border: 1px solid #000 ;  padding: 5px 15px; text-align: center; border-radius: 3px; }
.nextbtn{height: 50px; cursor: pointer;  min-width: 120px; padding: 20px 30px;border: 1px solid #000; background: var(--btn-bg-color); color: var(--btn-text-color); padding: 5px 15px; text-align: center; border-radius: 3px;}
.nextbtn:hover{background: var(--btn-hover-color);  }
.popover .nextbtn{  min-width: 150px;}
.resultbox{ border: 1px solid var(--box-border); border-radius: 5px; padding: 10px; background: #fffcfa; }
.resultrow  { font-family: var(--heading-fontfamily); border-bottom: 1px solid var(--box-border);   padding: 10px 5px; }
.finalpopoverdata .resultbox{ border: 2px solid var(--line-color); padding: 0px; }
.finalpopoverdata .resultbox .resultrow{  padding: 10px 15px; }
.resultbox .resultrow:last-child:not(.borderline){border-bottom:none ;     }
.resultrow strong { color:  #969696; font-weight: 400; font-family: var(--content-fontfamily);}
.linktext{ color: var(--link-text); text-decoration: underline;}
.myring,.myring img{  border-radius: 8px;}
.btn-lg{ width: 210px; height: 62px; line-height: 55px; font-size: 20px; font-weight: bold;}
.footertotal{ background: var(--btn-bg-color); color: #fff; font-weight: 500;}
.footertotal b{ font-size: 21px;}
.resultrow.footertotal strong { color: #fff;    position: relative;
  top: 6px;}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; justify-content: center; align-items: center;
 
}
.popover-clicon{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; cursor: pointer; margin-bottom: 10px;
  right: -4px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 30px;
  border: 0.75px solid var(--Grey, #CFCFCF);
  background: var(--White, #FFF);}
/* toggle button */
/* Background of the toggle button */
.toggle {
  width: 3rem;
  height: 1.5rem;
  background-color: #D1D5DB;
  border-radius: 3rem;
  position: relative;
  cursor: pointer;
}
.toggle.on{
  background-color: var(--btn-bg-color);
}
/* Sliding knob */
.slider {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #FFF;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: translateX(0);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  display: none !important;
}
/* Move the knob to the right when the toggle is on */
 
/* Move the knob to the right when the toggle is on */
.toggle.on .slider {
  transform: translateX(1.5rem);
}
.categories li:not(:first-child){
  /* pointer-events:none; */
}
/* Loading icon */

 .slick-list{ max-width: 800px;
  border: 2px solid  #F9DECA;border-radius: 25px;  }
 .lds-ring {
 display: flex; height: 80px; justify-content: center;
 }
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ddd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ddd transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.uploadimginput{ width:115px;}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px; 
  position: absolute;
  top: 0;
  left: 0;
  width: 10px !important;
  height: 10px !important;
  font-size: 11px;

}
.slick-active button:before {
  color: transparent !important;
  border: 1px solid #000;
  border-radius: 50%;
  top:4px !important;
}
.line-inherit{ line-height: normal;}
.sharedicons{ position: absolute; top: 10px; right: 10px; display: flex; column-gap: 10px; border: none !important;}
.sharedicons div.fileshare { background-color: #5b5b5a; padding: 5px; color: #fff; border-radius: 4px;}
/*slider css */
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  width: 2px;  
  height: 16px;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9 !important;
 border-radius: 50% !important;  
  cursor: pointer;
}
.slider-nodots .rc-slider-step{ display: none;}
div.rc-slider-handle{ border: solid 2px var(--line-color); background: #fff; opacity: 1;}
.rc-slider-handle:active,.rc-slider-handle:hover {
  border-color: var(--line-color) !important;
  box-shadow:  inherit; 
}
.rc-slider { font-family: var(--content-fontfamily);}
.rc-slider-mark-text-active { font-family: var(--heading-fontfamily);}
.rc-slider-track, .rc-slider-tracks {  background-color:var(--box-border) !important;
}
.rc-slider-mark-text-active{ color: var(--link-text) !important; }
.dragingslider { padding: 20px; padding-top: 0px;}
.show-mobile{ display: none;}
.inputborder-val{ border-radius: 5px; border: 2px solid var(--box-border); width: 100px; padding: 0px 5px;}
.Diamondicon{ color: #d0d9f0;}
.Rubyicon{ color: #e82e45;}
.Blue.sapphireicon{ color: #1c24a0;}
.Emeraldicon{ color: #069a6a;}
.Citrineicon{ color: #ffb130;}
.Amethysticon{ color: #954df6;}
.topazicon{ color: #3e9bf1;}
.Tanzaniteicon{ color: #6c60fb;}
.quartzicon{ color: #ae7a5b;}
.Morganiteicon{ color: #f9d2c9;}
.text-red{ color: red;}
input.error,textarea.error{border: 1px solid red;}
.errormsg{ color: red;}
.privacyscroll{ max-height: 50vh; overflow-y: auto;}
.imgcloseicon{ right: 10px; top:0px; font-weight: bold;}
.previmages { column-gap: 10px;}
.previmages img{ padding:5px; border-radius: 8px;border:1.5px solid var(--Light-Grey, #EFEFEF); }
.previmages .activepreive img{border-color: var(--line-color);}
.previmages .activepreive .imgcloseicon{ color: var(--blue-font);}
.uplodfilebtn { padding: 10px; text-align: center; display: flex; align-items: center; gap: 10px;border: 1px solid #d3cccc;
  border-radius: 5px; white-space: nowrap;
}
.ringbuilderimgs{ min-height: 240px;}
.containerpadding{ padding: 0px 15px;}
.rc-slider-dot {
  background-color: #e9e9e9 !important;
  border: 1px solid transparent !important;
  border-radius: 0%;
  bottom: -6px !important; 
  height: 18px !important; 
  width: 1px !important;
}
  /* .accordianborder  .p-4.bg-white {
  overflow: hidden;
  min-height: 0px;
  height: 0px;
  animation: slideDown 10s ease-out forwards;
}    */
.nextbtn.disable{ background: #cfcfcf; color: #9d9d9d; border:1px solid #9d9d9d }
.clarityicons img:nth-child(n+2){
position: relative; left: -5px;
}
.clarityicons img:first-child{position: relative; left: -10px; }
.clarityicons img:last-child{position: relative; right:-5px; left: inherit;}
  /* @keyframes slideDown {
  0% {
    max-height: 0px;
  } 
  100% {
    max-height: 1600px;
  }
}   */
 
.slide-up, .slide-down {
  overflow:hidden;
}
  .slide-down > div {
  overflow-y: hidden;
	max-height: 2500px; 
  transition-property: all;
	transition-duration: 0.50s;
	transition-timing-function: ease; 
}
.slide-up > div  {
  overflow-y: hidden;
	max-height: 2500px; 
  transition-property: all;
	transition-duration: 1.5s;
	transition-timing-function: ease; 
}
 .slide-down > div{
	max-height: 0;
}  
.contentlist ul { padding: 1rem; gap:10px}
/* .accordianborder   {
  height: 0px;
   overflow: hidden;
   transition:  height 1s;
 }
 .slide-down {
   height: 800px;
 
 } 
 .slide-up {
  height: 50px;
   
 } */
 
.Privacypolicytext  h4{color: #000; font-size: 16px; margin: 15px 0px; font-family: var(--content-fontfamily);}
.headingq { font-weight: bold;}
.Privacypolicytext p{ margin-bottom: 15px;}
.toggle .slider span{ display: flex; align-items: center; justify-content: center; height: 100%;}
 .closest{ display: none !important;}
 .toggle.on .slider .closesl{ display: none !important;}
 .toggle.on .slider .closest{ display: flex !important;}
 .col-hide{ display: none;}
 .activeheader .col-hide{ display: block;}
 .rc-slider-mark-text{ font-size: 9px;}
 .colorclarityicon{ width: 60px; cursor: pointer;}
 @media (min-width: 768px) {
 .contentlist ul li .gap-5{    gap: 0.50rem;}
 .pl-15 { padding-left: 40px;}
.nextbtn ,.backbtn{ margin-top: 23px;}
.alignbtns{ min-height: 370px;}
.catimg img{height: 200px;}
.categories  { max-width: 1000px; margin: 0px auto;}
.submitdesign { max-width: 1000px; margin: 0px auto; margin-top: 2.5rem; }
.categories li .catimg {  justify-content: center;  display: flex;}
.slick-list .slick-slide p{  margin: 0px 20px 20px 20px;} 
.lg-text-lg{font-size: 1.125rem;}
.steppercontainer { max-width: 95%; margin: 0px auto;}
.btnlist{ margin-top: 50px;}
.rc-slider-mark-text {
  font-size: 12px;
}
.lgnowrap { white-space: nowrap;}
.videoheight{ height: 450px;}
}
@media (max-width: 640px) { 
  h1{ font-size: 21px;  }
  h1.categoryhead { font-size: 26px;}
  .catname{ font-size: 12px;}
  h4{ font-size: 18px;}
  h4.mbselected{ font-size: 14px;}
 .headingtitle{ text-align: center; font-size: 11px; }
 .headingtitle br{ display: none;}
 .contentlist ul {  padding: 0.5rem;gap: 0.5rem;}
 .containerpadding { padding: 0 5px;}
 .categories h4{top:5px; }
.categories{ gap: 10px;}
.submitdesign{padding: 25px 15px; margin-top:20px}
.submitdesign h4{ text-align: center;}
.submitdesign .headingtitle { margin-bottom: 15px;}
.submitdesign button{ margin-top: 0px;}
.categories li{ padding: 10px;border-radius: 5px;}
.customsteper .w-10{ width: 16px; height: 16px;font-size: 10px;}
.icontext-sm{font-size: 11px;}
.customsteper{ margin-bottom: 50px !important; padding: 0px 15px;}
.accordianborder ul li{ line-height: inherit; }
.customlist-mb li{ padding-bottom: 25px !important;}
.gobackmob{ width: 50%; }
.backbtn,.nextbtn  { min-width: inherit;  width: 46%;}
.gobackmob .backbtn{ width: 100%; }
.contentlist li svg,.contentlist li img{font-size: 18px !important;width: 18px;}
.contentlist li img {max-width: inherit;}
  .mb-textleft{ text-align: left !important;}
  .mb-textcenter{ text-align: center !important;}
  .accordianborder button {  padding: 8px;font-size: 16px !important; line-height: inherit;}
  .accordianborder button .lightfont { font-size: 10px !important;}
  .accordion-item  .accorHeader { align-items: flex-start;}
  .contentlist ul li p.lightfont{ font-size: 10px !important;}
  .show-mobile{ display: block; margin-bottom: 10px;}
  .selectedvallist li { margin-top: 5px; margin-bottom: 10px;}
  .hide-mobile{ display: none;}
  .mobile-btn-full.flex{ flex-direction: column; row-gap: 15px; column-gap: 15px;}
  .mobile-btn-full button{ width: 100%;}
  .mbw-8{ width: 3rem;} 
  .mbh-8 { height: 3rem;}
  .contentlist ul li span{ align-items: center;}
  .selectedvallist li{ padding: 2px 10px;}
  .accordion-item ul li,.accordianborder ul li.active{ font-size: 11px; position: relative;}
  .mobile-btn-full.mbreverse{ flex-direction: column-reverse;}
  .accordion-item ul li .gap-5 {gap: 0.25rem;}
  .customlist-mb li,.customlist-mb li .flex { flex-direction: column;text-align: center;  }
  .customlist-mb   li input[type="radio"] { position: absolute; right: 10px; top:10px}
  .customlist-mb  li span img{ margin: 0px auto; width: 75px !important;  margin-top: 30px; margin-bottom: 20px;}
  .customlist-mb  li p{ margin-top: 10px;}
  .containerpadding>div{ width: 100%;}
  .pl-15{ padding: 0px;}
  .hide-mobile{ display: none;}
  .show-mobile{ display: block;}
  .mb-fullwidth{ width: 100%;}
  .mb-flexwrap { flex-wrap: wrap;}
  .mb-dirccol{ flex-direction: column;}
  .popular-tag{  position: absolute; left: 10px; top:10px}
  .show-mobile .selectedvallist li {background: #EFEFEF; font-size: 11px;  border: 1px solid #CFCFCF;color: #545454;}
    .mb-fontsize21{ font-size: 21px;}
    
    .slick-list { max-width: 300px; border-radius: 15px;
      border: 2px solid  #F9DECA;   }
      .slick-slider.slick-initialized { display: flex; justify-content: center;}
    .mb-drectioncol{ flex-direction: column;}
    .uplodfilebtn{background:  #CFCFCF; justify-content: center;}
element.style {
  animation: 0.5s ease 0s 1 normal none running zoomIn;
  transform-origin: center center;
}
 .footertotal b{font-size: 1.0rem;}
div.popover {
  -webkit-border-radius: 25px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius: 25px;
  -moz-border-radius-bottomright: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-radius: 25px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.resultrow{ font-size: 12px;padding: 5px;text-align: right; }
.resultrow strong{ text-align: left;white-space: nowrap;}
.resultbox { 
  max-height: 50vh;
  overflow: auto;
}
.privacyscroll{ max-height:40vh; overflow-y: auto;}
.inputborder-val{ width: 70px;font-size: 12px;}
.flex.justify-between.mt-4.mb-4 .flex.gap-4 {   width: 50%;} 
.flex.justify-between.mt-4.mb-4 .flex.gap-4 button{   width: 100%;} 
.mb-mb-20{ margin-bottom: 20px;}
.mb-hide{ display: none;}
.popover p.lightfont {font-size: 11px !important;}
.popover p.lightfont br{ display: none;}
.toggle{ width: 4rem;}
.mb-priceset{ margin-left: -15px;}
.show-mobile .selectedvallist { margin-bottom: 10px;}
.widthfull{ width: 100% !important;}
.col-gap-2 { column-gap: 10px;}
.backdrop { align-items: start; padding-top: 10px;}
.btnlist { margin-top: 40px;} 
.customsteper { margin: 0px 0px 40px !important;}
.submitbtn{height: 50px; padding: 0px;margin-top: 10px !important;}
svg.widget-svg { width:35px !important}
.rating.popover{    padding: 30px 30px !important;}
.rating.popover .nextbtn { margin-top: 50px !important;}
.whoopsmsg{ max-width: 300px;}
.rating.popover .popover-clicon {
  right: -10px;
}
.colorclarityicon{ width: 40px; cursor: pointer;}
.mbpoptop {align-items:baseline}
}





/* CSS */
.backbtn span, .nextbtn span{ font-size: 19px; }
.customradiobtn{ position: absolute; top:10px; right: 10px;}
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border: 3px solid #969696;
  border-radius: 50%;
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: #003459;border: 3px solid #fff;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}
.star-container2 svg {
  stroke: #888;   
  stroke-width: 2;    
} 
/* .radio-container .radio-checkmark:after {
  top: 10px;
  left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
} */
.radio-container svg { display: none; color: #fff;}
.radio-container input:checked ~ svg { display: block;}
.radio-container svg{position: absolute;
left: 9px;
z-index: 9;
top: 9px;
font-size: 17px;
}
.privaicybox{ border: 2px solid #F9DECA; padding: 10px; border-radius: 8px; font-size: 21px; }
.privaicybox .lg-text-lg {font-size: 21px; }
.popoveralign{ display: flex; align-items: center; justify-content: center;}
.scoialiconslist { position: absolute; display: flex;right: 0px; top:30px; }
.lodingspace {
  margin: 75px 0px;
}
.hidepriceval{  display: none;}
.open .hidepriceval{  display: block;}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} 
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.Loginform{ max-width: 500px; margin: 0px auto; height: 100vh; display: flex; align-items: center;}
.Loginform label{    color: var(--blue-font);}
.Loginform  h1 span{border-bottom:2px solid var(--blue-font); padding-bottom: 5px; }
.steppercontainer .activeicon  { cursor: pointer;}
/* .Loginform form{ border:2px solid var(--blue-font); border-radius: 10px; padding: 50px;} */
.overflowcustom:not(.open) { overflow: inherit;}
.clip-bottom {
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);

}
.copitedtext{  position: relative;  top: -65px;
  white-space: nowrap;
  color: green;}
  .linkicon svg:visited{ color: #fff;}
.boxborder { 
  border: 1px solid #d3cccc;
  margin: 2px 0px;
  padding: 5px;
  border-radius: 8px;
  background: var(--main-img-bgcolor);
}
  .fade {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-out {
    opacity: 0;
  }
.height0 { height: 0;}
.heightauto { height: auto;}
.backbtn:hover{ background: var(--btn-hover-color); color: #fff;}
 textarea{resize:none} 
